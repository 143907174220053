<template>
  <v-menu offset-y class="secondary lighten-3" v-if="$vuetify.breakpoint.smAndUp">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" text elevation="0" active-class="no-active" small color="primary lighten-1">
        Show examples
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-title @click="downloadCSV">Csv</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title @click="downloadText">Txt</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-title @click="downloadExcel">Excel</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
export default {
  data() {
    return {
      text: '0x867B9675B9289F5017A3b5453Ec265e36a12E10d,10\n0x1CbF2343a22Cea83c040a58F8305B81F163c7013,10',
      sampleData: [
        { address: '0x655d22e42769F152006FCfbae5131e2073E17FB8', amount: '100' },
        { address: '0xF8E06a1D098B1E0b804E347b0137374e760db29c', amount: '200' },
        { address: '0x8eEF99192D814f0B9B2e7256eE2EE518FFBa58DF', amount: '100' },
      ],
    }
  },
  methods: {
    downloadText: function () {
      var blob = new Blob([this.text], { type: 'text/plain' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'sample.txt'
      link.click()
    },
    downloadCSV: function () {
      let sampleData = '\ufeff' + 'address,amount\n'
      this.sampleData.forEach((el) => {
        var line = el['address'] + ',' + el['amount'] + '\n'
        sampleData += line
      })
      var blob = new Blob([sampleData], { type: 'csv/plain' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'sample.csv'
      link.click()
    },
    downloadExcel: function () {
      const worksheet = XLSX.utils.json_to_sheet(this.sampleData)
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
      })
      saveAs(data, `sample.xlsx`)
    },
  },
}
</script>
